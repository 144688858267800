<!--
 * @description: 
 * @Author: HaoJG
 * @Date: 2022-02-14 09:16:15
 * @Lasteditors: HaoJG
 * @LastEditTime: 2022-02-14 09:58:51
-->
<template>
  <div class='aicaigousign'>
    <div v-if='successinfo!=""' class='signinfo' v-html='successinfo'>

    </div>
    <div v-else>
      <div class="signinput">
        <el-input v-model="company" placeholder="请输入公司名称"></el-input>
      </div>
      <div class="signinput">
        <el-input v-model="name" placeholder="请输入您的姓名"></el-input>
      </div>
      <div class="signinput">
        <el-input v-model="position" placeholder="请输入您的职务"></el-input>
      </div>
      <el-button type='primary' @click="sign">提交</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      company: '',
      position: '',
      name: "",
      webname: this.WEBNAME,
      successinfo: '',
      openid: '',
      avator: ''
    };
  },
  mounted() {
    // this.request();
  },
  computed: {

  },
  methods: {
    getUrlKey(name) {//获取url 参数
      return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null;
    },
    sign() {
      this.$axios({
        url: this.HOST + '/member.login/aicaigousign',
        method: 'post',
        data: {
          company: this.company,
          position: this.position,
          name: this.name,
          openid: this.openid,
          avator: this.avator
        }
      }).then(res => {
        if (res.data.status == 1) {
          if (res.data.successinfo) {
            this.successinfo = res.data.successinfo;
          }
        }
      });
    }
  },
  created() {
    let code = this.getUrlKey('code');
    if (code) {
      this.$axios({
        url: this.HOST + '/member.login/aicaigoulogin',
        method: 'post',
        data: {
          code: code
        }
      }).then(res => {
        if (res.data.status == 1) {
          if (res.data.successinfo) {
            this.successinfo = res.data.successinfo;
          } else {
            this.openid = res.data.openid;
            this.avator = res.data.avator;
          }
        }
      });
    } else {
      let url = 'http://auth.qlsww.com/auth/get?license=aicaigou';
      // let url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxefc403f992b130a4&redirect_uri=" + window.location.href + "&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect";
      window.location.replace(url)
    }
  }
};
</script>