<template>
  <div class='sign'>
    <div v-if='successinfo!=""' class='signinfo' v-html='successinfo'>

    </div>
    <div class="signdiv" v-else>
      <div class="signinput">
        <el-select v-model='slctDep' @change="getMember" style='width:100%' placeholder="请选择部门">
          <el-option v-for="(item,index) in deplist" :key='index' :label='item.title' :value='item.id'></el-option>
        </el-select>
      </div>
      <div class="signinput" v-if='slctDep==1'>
        <el-input v-model="name" placeholder="请输入姓名"></el-input>
      </div>
      <div class="signinput" v-else>
        <el-select v-model='slctMember' style='width:100%' placeholder="请选择您的姓名">
          <el-option v-for="(item,index) in memberlist" :key='index' :label='item.name' :value='item.id'></el-option>
        </el-select>
      </div>
      <el-button @click="sign">提交</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      deplist: [],
      memberlist: [],
      slctDep: '',
      slctMember: '',
      name: "",
      webname: this.WEBNAME,
      successinfo: '',
      openid: '',
      avator: ''
    };
  },
  mounted() {
    // this.request();
  },
  computed: {

  },
  methods: {
    getUrlKey(name) {//获取url 参数
      return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null;
    },
    getMember() {
      this.memberlist = [];
      this.$axios({
        url: this.HOST + '/member.login/getMemberByDep',
        method: 'post',
        data: {
          id: this.slctDep,
        }
      }).then(res => {
        if (res.data.status == 1) {
          this.memberlist = res.data.list;
          this.slctMember = ''
        }
      });
    },
    sign() {
      this.$axios({
        url: this.HOST + '/member.login/sign',
        method: 'post',
        data: {
          dep: this.slctDep,
          member: this.slctMember,
          name: this.name,
          openid: this.openid,
          avator: this.avator
        }
      }).then(res => {
        if (res.data.status == 1) {
          if (res.data.successinfo) {
            this.successinfo = res.data.successinfo;
          }
        }
      });
    }
  },
  created() {
    let code = this.getUrlKey('code');
    if (code) {
      this.$axios({
        url: this.HOST + '/member.login/login',
        method: 'post',
        data: {
          code: code
        }
      }).then(res => {
        if (res.data.status == 1) {
          if (res.data.successinfo) {
            this.successinfo = res.data.successinfo;
          } else {
            this.openid = res.data.openid;
            this.avator = res.data.avator;
            this.deplist = res.data.deplist;
          }
        }
      });
    } else {
      let url = 'http://auth.qlsww.com/auth/get?license=choujiang';
      // let url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxefc403f992b130a4&redirect_uri=" + window.location.href + "&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect";
      window.location.replace(url)
    }
  }
};
</script>